import axios from "axios";

const discordReport = async (message, webhook) => {
  let isRunningInPWA = false;
  if (window.matchMedia("(display-mode: standalone)").matches) {
    isRunningInPWA = true;
  }
  await axios.post(webhook, {
    content: `${isRunningInPWA ? `(Web App) ` : ``}${message}`,
    embeds: null,
    attachments: [],
  });
};

export default discordReport;
