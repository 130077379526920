import { ThemeProvider } from "theme-ui";
import theme from "theme";
import Layout from "@/components/Global/layout";
import { Global, css } from "@emotion/react";
import { AppContextProvider } from "context/AppContext";
import { useEffect } from "react";
import "styles/fonts.css";
import * as gtag from "util/gtag";
import discordReport from "util/discordReport";
import getCountryEmoji from "util/getCountryEmoji";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import * as fbq from "../lib/fpixel";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  useEffect(() => {
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    console.log("📟 VIEW", "Home");
    gtag.pageview("Home");
  }, []);
  useEffect(() => {
    try {
      const emojiFlag = getCountryEmoji();
      discordReport(
        `✨ Someone is visiting from ${emojiFlag} ${router.asPath}`,
        process.env.NEXT_PUBLIC_DISCORD_WH_VISIT
      );
    } catch (error) {
      console.log(error);
    }
  }, [router]);

  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <GlobalStyle theme={theme} />
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
        <Layout>
          <Head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
            <meta
              name="facebook-domain-verification"
              content="s99vb1hv4lab9v26to2exjublukkna"
            />
          </Head>
          <Component {...pageProps} />
        </Layout>
      </AppContextProvider>
    </ThemeProvider>
  );
}

const GlobalStyle = (props) => {
  return (
    <Global
      styles={css`
        html {
          font-family: ${props.theme.fonts.body};
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
        }
        * {
          box-sizing: border-box !important;
          font-family: ${props.theme.fonts.body};
        }
        body {
          font-family: ${props.theme.fonts.body};
          right: 0;
        }
        p {
          margin: 0;
        }
        a,
        button {
          color: inherit;
          text-decoration: none;
        }
        input {
          outline: none;
        }
        .App {
          text-align: center;
        }
        .react-grid-item {
          transition: all 500ms ease 0s;
        }
        .react-grid-placeholder {
          &,
          * {
            background-color: ${props.theme.colors.brutal.yellow} !important;
          }
        }
      `}
    />
  );
};

export default MyApp;
