import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import AdSense from "react-adsense-ads";

const SmallHorizontalAd = ({ width, height }) => {
  return (
    <Wrapper>
      <AdSense.Google
        client="ca-pub-6028402156043755"
        slot="6365805633"
        format="auto"
        style={{ width, height }}
      />
    </Wrapper>
  );
};
export default SmallHorizontalAd;

const Wrapper = styled(Box)`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
`;
