import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box, Container as ContainerComponent, Text } from "theme-ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { AiFillHome } from "react-icons/ai";
import { css } from "@emotion/react";
import discordReport from "util/discordReport";
import getCountryEmoji from "util/getCountryEmoji";

const Header = () => {
  const router = useRouter();
  if (router?.asPath === "/home") return null;

  const [shake, setShake] = useState()

  const shakeInterval = useRef()

  const shakeIcon = (custom=null) => {
    shakeInterval.current = setTimeout(()=> {
      setShake(true)
      setTimeout(()=>{
        setShake(false);
        shakeIcon();
      }, 2000)
    }, custom || _.random(3000,19000))
  }

  useEffect(()=> {
    shakeIcon(3500);
  }, [])

  const handleReport = () => {
    try {
      const emojiFlag = getCountryEmoji();
      discordReport(
        `🏠 Home is clicked! from ${emojiFlag}`,
        process.env.NEXT_PUBLIC_DISCORD_WH_VISIT_HOME
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Wrapper>
      <Container px={[4]}>
        <LogoWrapper as="a" href="/">
          MyJeep.
        </LogoWrapper>
        <HomeNav shake={shake} onClick={handleReport}>
          <Link href="/home">
            <AiFillHome />
          </Link>
        </HomeNav>
      </Container>
    </Wrapper>
  );
};
export default Header;

const Wrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  height: ${({ theme }) => theme.navHeight};
  display: flex;
  align-items: center;
`;
const LogoWrapper = styled(Box)`
  font-family: roboto-black;
  font-size: 1.2rem;
  cursor: pointer;
`;

const Container = styled(ContainerComponent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavText = styled(Text)`
  font-size: 0.8rem;
`;

const HomeNav = styled(Box)`
  svg {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
  }

  ${({ theme }) => theme.animation.wiggle};

  @keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
  ${({ shake }) => shake ?css`animation: tilt-shaking 200ms 8 linear;` : ``};
`;
