import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box, Button, Flex, Text } from "theme-ui";
import Modal from "../Modal";
import { FaTiktok } from "react-icons/fa";
import Image from "next/image";
import Link from "next/link";
import RouteItemAd from "../GoogleAds/RouteItemAd";
import _ from "lodash";
import { Balancer } from "react-wrap-balancer";

const Ad = () => {
  const [show, setShow] = useState();

  const timer = useRef(null);
  const [timeSec, setTimeSec] = useState(_.random(3e3, 4e3));

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!show && !timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
      timer.current = setTimeout(() => {
        setTimeSec(_.random(45e3, 140e3));
        setShow(true);
        clearTimeout(timer.current);
        timer.current = null;
      }, timeSec);
    }
  }, [show]);

  return (
    <Modal show={show} noCloseButton noPaddding>
      <OverviewWrapper>
        <SmText p={4}>
          Hey there! Help us out by checking our sponsor and ads. <br /> To
          close, simply scroll down. Thanks heaps! 😊
        </SmText>
      </OverviewWrapper>
      <ContentWrapper p={4}>
        <Box>
          <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
            <Balancer>
              <Text
                sx={{
                  fontFamily: "monument-ultra",
                  textTransform: "uppercase",
                  textAlign: "center",
                  display: "block",
                  fontSize: "24px",
                  lineHeight: "25px",
                }}
              >
                MyJeep is now available on Apple Store and Google Playstore!
              </Text>
            </Balancer>
            <Flex></Flex>
            <Link href={"https://apps.apple.com/ph/app/myjeep/id6461773590"}>
              <Button mt={3} sx={{ fontSize: "14px" }}>
                Download on Apple Store
              </Button>
            </Link>
            <Link
              href={
                "https://play.google.com/store/apps/details?id=com.kianvilleno.myjeep"
              }
            >
              <Button mt={1} sx={{ fontSize: "14px" }}>
                Download on Google Play
              </Button>
            </Link>
          </Flex>
        </Box>
        <Box sx={{ position: "relative" }} mt={4}>
          <Image
            src={"/image/downloadNow.png"}
            width={500}
            height={500}
            style={{ width: "100%", height: "auto", borderRadius: "30px" }}
          />
        </Box>
        <GoogleAds mt={5}>
          <RouteItemAd />
          <RouteItemAd />
          <RouteItemAd />
          <RouteItemAd />
          <RouteItemAd />
        </GoogleAds>
        <Button mt={4} sx={{ fontSize: "14px" }} onClick={handleClose}>
          Close 👋
        </Button>
      </ContentWrapper>
    </Modal>
  );
};
export default Ad;

const SmText = styled(Text)`
  font-size: 13px;
  line-height: 136%;
  display: block;
`;
const HeadingText = styled(Text)`
  font-size: 16px;
  line-height: 136%;
  display: block;
  font-family: ${({ theme }) => theme.fonts.heading};
`;
const SubText = styled(Text)`
  font-size: 12px;
  line-height: 136%;
  display: block;
`;
const ProductDesc = styled(Text)`
  font-size: 12px;
  line-height: 126%;
  display: block;

  b {
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;

const SocialLinks = styled(Box)``;

const NameText = styled(Text)`
  font-size: 14px;
  line-height: 136%;
  display: block;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const FrameWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const FeaturedProducts = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
`;
const FeaturedProduct = styled(Box)``;

const ImageWrapper = styled(Box)`
  img {
    object-fit: cover;
    border: 1px solid ${({ theme }) => theme.colors.grey[2]};
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

const Avatar = styled(Box)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.grey[3]};
  border-radius: 100%;
  background-image: url("/image/tiktok-avatar.jpg");
  background-size: cover;
`;

const OverviewWrapper = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[3]};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  position: sticky;
  top: 0;
`;

const ContentWrapper = styled(Box)`
  /* background-color: ${({ theme }) => theme.colors.grey[1]}; */
`;
const GoogleAds = styled(Box)``;

// https://shp.ee/kv2k5j9
// https://invl.io/clj6ky9

// https://shp.ee/3dh2y7t
// https://invl.io/clj6kz5

// https://shp.ee/a7x326n
// https://invl.io/clj6kzh

// https://shp.ee/chk792g
// https://invl.io/clj6kzt

// https://shp.ee/zmrxbd2
