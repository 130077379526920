import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { GrClose } from "react-icons/gr";
import { AnimatePresence, motion } from "framer-motion";

const Modal = ({ children, show, state, noCloseButton, noPaddding }) => {
  const handleClose = () => {
    state(false);
  };

  const contentRef = useRef();

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
  };

  const animationContent = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
    transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98], delay: 0.5 },
  };

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [show]);

  return (
    <AnimatePresence>
      {show && (
        <Wrapper as={motion.div} {...animation}>
          <Backdrop />
          <ContentWrapper
            as={motion.div}
            p={!noPaddding && [4]}
            {...animationContent}
            ref={contentRef}
          >
            <Header>
              {!noCloseButton && (
                <Box onClick={handleClose}>
                  <GrClose />
                </Box>
              )}
            </Header>
            {children}
          </ContentWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
export default Modal;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1080;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;
  position: absolute;
  top: 0;
`;
const ContentWrapper = styled(Box)`
  width: 90%;
  max-width: 450px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const Header = styled(Box)`
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
`;
