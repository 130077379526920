export const GA_TRACKING_ID = "G-XBQ9FJ278N";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, val = {}) => {
  window.gtag("event", action, val);
};
