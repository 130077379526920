import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Container } from "theme-ui";
import Header from "./Header";
import { useRouter } from "next/router";
import Welcome from "../Welcome";
import { AppContext } from "context/AppContext";
import SaveImage from "../SaveImage";
import Modal from "../Modal";
import Ad from "../Ad";

const Layout = ({ children }) => {
  const router = useRouter();
  const isHome = router.pathname === "/";
  const { contentLoaded, showDownloadImage } = useContext(AppContext);
  useEffect(() => {
    const dom = document.querySelectorAll("html, body");
    if (isHome) {
      dom.forEach((el) => {
        el.style["overflow"] = "hidden";
        el.style["touch-action"] = "none";
        el.style["-ms-touch-action"] = "none";
      });
    } else {
      dom.forEach((el) => {
        el.style["overflow"] = "unset";
        el.style["touch-action"] = "unset";
        el.style["-ms-touch-action"] = "unset";
      });
    }
  }, [router]);

  return (
    <>
      {isHome && <Welcome contentLoaded={contentLoaded} />}
      <Header />
      {showDownloadImage && <SaveImage />}
      <Container>{children}</Container>
      <Ad />
      {/* <InstallInstruction /> */}
    </>
  );
};
export default Layout;

const Wrapper = styled(Box)``;
