import { css } from "@emotion/react";
import facepaint from "facepaint";
const breakpoints = ["576px", "1200px"];
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp})`));
const navHeight = "45px";
const layout = {
  container: {
    maxWidth: ["100%", "800px"],
  },
};
const buttons = {
  primary: {
    backgroundColor: "black",
    borderRadius: "50px",
    cursor: "pointer",
  },
  brutal: {
    border: "1px solid #222",
    boxShadow: "8px 8px 0 #222",
    backgroundColor: "transparent",
    color: "#222",
    borderRadius: "50px",
    cursor: "pointer",
  },
};
const animationAll = css`
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;
const glassEffect = css`
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;
const boxShadow = css`
  box-shadow: 1px 1px 20px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 1px 20px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 20px -4px rgba(0, 0, 0, 0.2);
`;
const brutalBoxShadow = css`
  box-shadow: 8px 8px 0 #222;
`;
const colors = {
  brutal: {
    blue: "#38dbff",
    orange: "#ffb443",
    red: "#ff5d5d",
    yellow: "#fff503",
    green: "#00ff75",
    purple: "#dd7dff",
    pink: "#ff90e8",
  },
  white: "#fff",
  black: "#222222",
  grey: [
    "#fff",
    "#f8f9fa",
    "#e9ecef",
    "#dee2e6",
    "#ced4da",
    "#adb5bd",
    "#6c757d",
    "#495057",
    "#343a40",
    "#212529",
  ],
};
const space = [
  "0rem",
  "0.25rem",
  "0.5rem",
  "1rem",
  "1.5rem",
  "3rem",
  "3.5rem",
  "4rem",
];
const fonts = {
  body: "roboto-regular",
  heading: "roboto-black",
  bold: "roboto-bold",
};
const fontSizes = [
  "0.75rem",
  "0.9rem",
  "1rem",
  "1.25rem",
  "1.5rem",
  "1.75rem",
  "2rem",
  "2.5rem",
  "3.5rem",
  "4.5rem",
  "5.5rem",
  "6rem",
];
const fontWeights = {
  body: 400,
  heading: 500,
  bold: 700,
  light: 300,
  normal: 400,
  display: 300,
};
const lineHeights = {
  body: 1.5,
  heading: 1.2,
};
const radii = {
  default: "0.25rem",
  sm: "1rem",
  lg: "2.5rem",
  pill: "50rem",
};
const text = {
  s: {
    fontSize: "0.8rem",
  },
};
const styles = {
  root: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "body",
  },
  a: {
    color: "primary",
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
  p: {
    mb: 3,
    lineHeight: "body",
  },
  h1: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 7,
  },
  h2: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 6,
  },
  h3: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 5,
  },
  h4: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 4,
  },
  h5: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 3,
  },
  h6: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 2,
    fontSize: 2,
  },
  blockquote: {
    fontSize: 3,
    mb: 3,
  },
  table: {
    width: "100%",
    marginBottom: 3,
    color: "gray.9",
    borderCollapse: "collapse",
  },
  th: {
    verticalAlign: "bottom",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: "gray.3",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: "gray.3",
    padding: ".75rem",
    textAlign: "inherit",
  },
  td: {
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: "gray.3",
    verticalAlign: "top",
    padding: ".75rem",
  },
  inlineCode: {
    color: "pink",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
};
const theme = {
  breakpoints,
  layout,
  navHeight,
  mq,
  buttons,
  animationAll,
  glassEffect,
  boxShadow,
  brutalBoxShadow,
  colors,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  text,
  styles,
  animation: {
    blink: css`
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
      animation: blinker 500ms step-start infinite;
    `,
  },
  wiggle: css`
    @keyframes wiggle {
      0% {
        transform: skewX(9deg);
      }
      10% {
        transform: skewX(-8deg);
      }
      20% {
        transform: skewX(7deg);
      }
      30% {
        transform: skewX(-6deg);
      }
      40% {
        transform: skewX(5deg);
      }
      50% {
        transform: skewX(-4deg);
      }
      60% {
        transform: skewX(3deg);
      }
      70% {
        transform: skewX(-2deg);
      }
      80% {
        transform: skewX(1deg);
      }
      90% {
        transform: skewX(0deg);
      }
      100% {
        transform: skewX(0deg);
      }
    }
  `,
};
export default theme;
