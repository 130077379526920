import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { AnimatePresence, motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";

const Welcome = ({ contentLoaded }) => {
  const initial = {
    opacity: 1,
  };

  const exit = {
    opacity: 0,
  };

  const transition = {
    opacity: { duration: 1.3 },
    default: { ease: [0.17, 0.67, 0.83, 0.67] },
  };

  return (
    <AnimatePresence>
      {!contentLoaded && (
        <Wrapper
          as={motion.div}
          initial={initial}
          exit={exit}
          transition={transition}
        >
          <LogoText>
            <TypeAnimation sequence={["MyJeep."]} speed={1} />
          </LogoText>
          <InfoText>proudly made by 🇵🇭</InfoText>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
export default Welcome;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: #f0e9d2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1080;
  position: absolute;
  top: 0;
`;

const LogoText = styled(Text)`
  &,
  * {
    font-size: 2.5rem;
    font-family: roboto-black;
    line-height: 100%;
    margin-bottom: 0.3rem;
  }
`;
const InfoText = styled(Text)`
  font-size: 0.5rem;
  line-height: 100%;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
`;
