import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box, Button, Text } from "theme-ui";
import { AppContext } from "context/AppContext";
import JeepIcon from "../Route/RouteItem.js/JeepIcon";
import * as htmlToImage from "html-to-image";

const SaveImage = () => {
  const {
    routeDetails,
    routeData,
    originVal,
    destinationVal,
    setShowDownloadImage,
  } = useContext(AppContext);
  if (!routeDetails) return null;
  const { legs } = routeData;
  const [leg] = legs;
  const { steps, duration } = leg;
  const stepsTransit = steps.filter(
    ({ travel_mode }) => travel_mode === "TRANSIT"
  );
  const [imageData, setImageData] = useState(null);

  const contentRef = useRef(null);

  const handleSaveImage = (setState = false) => {
    const node = contentRef?.current;
    console.log(node);
    htmlToImage
      .toPng(node, { pixelRatio: 4 })
      .then(function (dataUrl) {
        if (setState) setImageData(dataUrl);
      })
      .catch(function (error) {
        console.error("🖼 Save image error! \n\n", error);
      });
  };

  useEffect(() => {
    setTimeout(() => handleSaveImage(), 1000);
    setTimeout(() => handleSaveImage(true), 2000);
  }, []);

  return (
    <Wrapper>
      {!imageData && <WaitingWrapper>Generating image....</WaitingWrapper>}
      <Backdrop />
      {!imageData && (
        <ContentWrapper ref={contentRef}>
          <HeaderWrapper px={4} py={2}>
            <Logo>MyJeep.</Logo>
          </HeaderWrapper>
          <Content p={4}>
            <OverviewWrapper mb={4}>
              <BigText>Jeep GOING TO</BigText>
              <SmallText>{destinationVal}</SmallText>
              <BigText>FROM</BigText>
              <SmallText mb={3}>{originVal}</SmallText>
              <DurationTextWrapper>{duration?.text}</DurationTextWrapper>
              <JeepInfoWrapper>
                <Text mr={1}>{stepsTransit?.length}</Text>
                <JeepIcon mr={2} />
                {stepsTransit?.map(({ travel_mode, transit }, i) => (
                  <SecondaryTransit>
                    {i > 0 ? <Text mx={1}>→</Text> : null}
                    <TextTransit>
                      {transit?.line?.short_name || transit?.line?.name}
                    </TextTransit>
                  </SecondaryTransit>
                ))}
              </JeepInfoWrapper>
            </OverviewWrapper>
            <RouteDetailsWrapper mt={4}>{routeDetails}</RouteDetailsWrapper>
          </Content>
        </ContentWrapper>
      )}
      <DownloadImageContent p={3}>
        <ImageWrapper>
          <img src={imageData || "/"} />
        </ImageWrapper>
        <InstructionsText mt={2}>
          Press and hold the image to save.
        </InstructionsText>
        <Button mt={2} onClick={() => setShowDownloadImage(false)}>
          X
        </Button>
      </DownloadImageContent>
    </Wrapper>
  );
};
export default SaveImage;

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -1;
  opacity: 0.69;
`;
const WaitingWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0e9d2;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.2rem;
`;
const ContentWrapper = styled(Box)`
  background-color: #ece8dd;
  width: 540px;
  min-height: 960px;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
`;
const Content = styled(Box)`
  width: 100%;
  height: 100%;
`;
const HeaderWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;
const Logo = styled(Box)`
  font-family: roboto-black;
  font-size: 1.2rem;
`;
const OverviewWrapper = styled(Box)``;
const BigText = styled(Box)`
  font-size: 1.2rem;
  line-height: 110%;
  font-family: roboto-black;
  text-transform: uppercase;
`;
const SmallText = styled(Box)`
  font-size: 0.8rem;
  line-height: 130%;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
  width: 90%;
`;

const RouteDetailsWrapper = styled(Box)`
  margin-left: -24px;
  margin-right: -24px;
  * {
    box-shadow: none;
  }
  .route-details-ad {
    display: none;
  }
`;

const SecondaryTransit = styled(Box)``;
const JeepInfoWrapper = styled(Box)`
  display: flex;
`;

const TextTransit = styled(Text)`
  font-family: roboto-black;
`;

const InfoWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const DurationTextWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.black};
  display: inline-block;
  color: white;
  padding: 0.5rem;
  border-radius: 10px;

  font-size: 0.8rem;

  margin-bottom: 0.5rem;
`;

const DownloadImageContent = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 400px;
  width: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Box)`
  position: relative;
  img {
    width: auto;
    max-height: 60vh;
    display: block;
  }
  justify-content: center;
  background-color: grey;
`;

const InstructionsText = styled(Box)`
  font-size: 0.6rem;
`;
