import emojiFlags from "@/data/emojiFlags";

const getCountryEmoji = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let emojiFlag; 
  Object.values(emojiFlags).forEach(({name,emoji})=> {
    if (String(timezone).toLowerCase().match((String(name).toLowerCase()))) {
      emojiFlag = emoji
    }
  });
  return emojiFlag || timezone || ""
};

export default getCountryEmoji
