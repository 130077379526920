import { FAVORITE_ROUTE_KEY, RECENT_ROUTE_SEARCH_KEY } from "@/data/constants";
import { createContext, useEffect, useState } from "react";

export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
  const [map, setMap] = useState(null);
  const [directionsResult, setDirectionsResult] = useState(false);
  const [routeIndex, setRouteIndex] = useState(0);
  const [expandMenubar, setExpandMenubar] = useState(false);
  const [originVal, setOriginVal] = useState("");
  const [destinationVal, setDestinationVal] = useState("");
  const [expandAddr, setExpandAddr] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false);
  const [center, setCenter] = useState({
    lat: 10.3157,
    lng: 123.8854,
  });
  const [zoom, setZoom] = useState(12);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [routeDetails, setRouteDetails] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [showDownloadImage, setShowDownloadImage] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [jeepneyRoutesHistory, setJeepneyRoutesHistory] = useState([]);
  const [favoriteRoutes, setFavoriteRoutes] = useState([]);
  const [showFavorites, setShowFavorites] = useState(false);
  const [swiperCurrIndex, setSwiperCurrIndex] = useState(0);
  const [routeDetailsData, setRouteDetailsData] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [currentPos, setCurrentPos] = useState(null);

  useEffect(() => {
    const getJeepneyRoutesHistory = localStorage.getItem(
      RECENT_ROUTE_SEARCH_KEY
    );
    if (getJeepneyRoutesHistory) {
      setJeepneyRoutesHistory(JSON.parse(getJeepneyRoutesHistory));
    }
    const getFavorite = localStorage.getItem(FAVORITE_ROUTE_KEY);
    if (getFavorite) {
      setFavoriteRoutes(JSON.parse(getFavorite));
    }
    // localStorage.clear();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      RECENT_ROUTE_SEARCH_KEY,
      JSON.stringify(jeepneyRoutesHistory)
    );
  }, [jeepneyRoutesHistory]);

  useEffect(() => {
    localStorage.setItem(FAVORITE_ROUTE_KEY, JSON.stringify(favoriteRoutes));
  }, [favoriteRoutes]);

  const contextValue = {
    map,
    setMap,
    directionsResult,
    setDirectionsResult,
    routeIndex,
    setRouteIndex,
    expandMenubar,
    setExpandMenubar,
    center,
    setCenter,
    originVal,
    setOriginVal,
    destinationVal,
    setDestinationVal,
    expandAddr,
    setExpandAddr,
    zoom,
    setZoom,
    currentLocation,
    setCurrentLocation,
    contentLoaded,
    setContentLoaded,
    routeDetails,
    setRouteDetails,
    routeData,
    setRouteData,
    showDownloadImage,
    setShowDownloadImage,
    swiper,
    setSwiper,
    jeepneyRoutesHistory,
    setJeepneyRoutesHistory,
    favoriteRoutes,
    setFavoriteRoutes,
    showFavorites,
    setShowFavorites,
    swiperCurrIndex,
    setSwiperCurrIndex,
    routeDetailsData,
    setRouteDetailsData,
    showInstructions,
    setShowInstructions,
    currentPos,
    setCurrentPos,
  };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
