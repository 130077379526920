import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import SmallHorizontalAd from "./SmallHorizontalAd";

const RouteItemAd = ({ index }) => {
  if (index < 1) return null;
  return (
    <Wrapper as="fieldset" mb={4} px={2}>
      <legend>Ad</legend>
      <SmallHorizontalAd width="100%" height="auto" />
    </Wrapper>
  );
};
export default RouteItemAd;

const Wrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: block;
  height: auto;

  legend {
    font-size: 14px;
  }
`;
